import { EnvironmentProviders, InjectionToken, Provider } from '@angular/core';
import { hasRoleWithinAdminPolicy } from '@shared/util/code';

export const hasRolePortalAdminPolicyFn = new InjectionToken<(role: string) => boolean>('Check if user is portal admin');

export function userConfig(isPortalAdmin: (role: string) => boolean = () => false): Provider | EnvironmentProviders {
  return [
    {
      provide: hasRolePortalAdminPolicyFn,
      useValue: (role: string) => hasRoleWithinAdminPolicy(role) || isPortalAdmin(role),
    },
  ];
}
