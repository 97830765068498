import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { getProjectGuid as getProjectOrReportGroupGuid } from '@shared/util/code';
import { Observable, of, ReplaySubject } from 'rxjs';
import { UserError } from './desan-error-handler';
import { getEnvironment } from './get-environment';
import { GuidInterceptor } from './guid.interceptor';

enum BaseHRef {
  ProjectRegistration = 5200,
  TenantPortal = 5000,
  SurveyAdmin = 5001,
  SurveyResponse = 5002,
  SurveyEditor = 5003,
  SurveyExport = 5004,
  ReportGroups = 5005,
  NodeJsServer = 5006,
  Reports = 5007,
}

const getBaseHref = (apiUrl: string) =>
  apiUrl
    .split('/')
    .filter((x) => x)
    .reverse()[0];
const getLocalEnvironmentPort = (apiUrl: string) => +apiUrl.split(':')[2]?.split('/')[0];

@Injectable({ providedIn: 'root' })
export class AppSettingsService {
  apiUrl!: string;
  portalApiUrl!: string;
  projectGuid?: string;
  exportApiUrl!: string;
  reportGroupsApiUrl!: string;
  reportApiUrl!: string;
  registrationApiUrl!: string;
  responseApiUrl!: string;
  adminApiUrl!: string;
  editorApiUrl!: string;
  portalAppUrl!: string;
  nodeJs!: string;
  hasProjectGuid = [BaseHRef.SurveyAdmin, BaseHRef.SurveyResponse, BaseHRef.SurveyExport, BaseHRef.SurveyEditor, BaseHRef.Reports]
    .map((x) => BaseHRef[x])
    .includes(getBaseHref(this.document.baseURI));

  readonly initDone$ = new ReplaySubject(1);
  private readonly browserStackIosHostname = 'bs-local.com';
  private readonly isBrowserStack = this.document.location.host.includes(this.browserStackIosHostname);

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.fixIisCaseInsensitiveAppPoolName(document);
  }

  init(apiUrl: string): Observable<AppSettingsService> {
    this.apiUrl = this.fixBrowserStackUrl(apiUrl); //browserStack correction
    this.registrationApiUrl = this.getToolApiUrl(BaseHRef.ProjectRegistration);
    this.exportApiUrl = this.getToolApiUrl(BaseHRef.SurveyExport);
    this.reportGroupsApiUrl = this.getToolApiUrl(BaseHRef.ReportGroups);
    this.reportApiUrl = this.getToolApiUrl(BaseHRef.Reports);
    this.responseApiUrl = this.getToolApiUrl(BaseHRef.SurveyResponse);
    this.adminApiUrl = this.getToolApiUrl(BaseHRef.SurveyAdmin);
    this.editorApiUrl = this.getToolApiUrl(BaseHRef.SurveyEditor);
    this.nodeJs = this.getToolApiUrl(BaseHRef.NodeJsServer);

    const projectOrReportGroupGuid = getProjectOrReportGroupGuid(this.document.location.pathname);
    this.projectGuid = GuidInterceptor.projectGuid = this.hasProjectGuid ? projectOrReportGroupGuid : undefined;

    let portalApiUrl = this.getPortalApiUrl();
    portalApiUrl = this.trimApiUrl(portalApiUrl);
    this.portalApiUrl = portalApiUrl = this.fixBrowserStackUrl(portalApiUrl);
    this.portalAppUrl = this.getTenantPortalAppUrl(portalApiUrl);
    this.initDone$.next(null);
    this.initDone$.complete();
    return of(this);
  }

  getLanguageJsonUrl(lang: string) {
    if (getEnvironment(this.document.location) !== 'local') {
      return `${window.location.origin}/root/translations/${lang}.json`;
    }

    return `${this.fixBrowserStackUrl(`https://${window.location.hostname}:5601`)}/translations/${lang}.json`;
  }

  private getTenantPortalAppUrl = (url: string) => {
    return getEnvironment(this.document.location) !== 'local'
      ? this.trimApiUrl(url)
      : `https://${this.document.location.hostname}:4200/ProjectPortal`;
  };

  private getPortalApiUrl(): string {
    if (this.document.baseURI.includes('Portal')) {
      return this.apiUrl;
    }
    const isLocal = getEnvironment(this.document.location) === 'local';
    return isLocal ? `https://${window.location.hostname}:${BaseHRef.TenantPortal}` : `https://${window.location.hostname}/ProjectPortal`;
  }

  private trimApiUrl = (apiUrl: string) => apiUrl.replace(/\/$/, '');
  private fixBrowserStackUrl = (apiUrl: string) => this.getApiUrl(getBaseHref(apiUrl), getLocalEnvironmentPort(apiUrl));
  private getToolApiUrl = (baseHref: BaseHRef) => this.getApiUrl(BaseHRef[baseHref], baseHref);

  private getApiUrl(baseHref: string, localEnvironmentPort: number): string {
    const location = this.document.location;
    switch (getEnvironment(location)) {
      case 'local':
        return this.isBrowserStack
          ? `http://${this.browserStackIosHostname}:${localEnvironmentPort + 100}`
          : `https://${window.location.hostname}:${localEnvironmentPort}`;
      default:
        return `${this.document.location.origin}/${baseHref}`;
    }
  }

  private fixIisCaseInsensitiveAppPoolName(document: Document) {
    // iis allow have itzhmw.desan.nl/ProjectPortal. Angular wants itzhmw.desan.nl/ProjectPortal
    // if not corrected angular will add an extra Base => itzhmw.desan.nl/ProjectPortal/ProjectPortal
    const orgHref = document.location.href;
    const newValue = document.baseURI.replace(/\/$/, '');
    const regex = new RegExp(`(${newValue})`, 'gmi');
    const newHref = orgHref.replace(regex, newValue);
    if (newHref !== orgHref) {
      console.log('BaseUri Fix', document.location.href, newHref);
      document.location.href = newHref;
    }
  }
}

export function throwUserError(errorMessage: string, title: string): never {
  throw new UserError(errorMessage, title);
}

export function throwExpression(errorMessage: string): never {
  throw new Error(errorMessage);
}
