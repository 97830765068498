import { Injectable } from '@angular/core';
import { ApplicationInsights, ICustomProperties, IEventTelemetry, ITelemetryItem, SeverityLevel } from '@microsoft/applicationinsights-web';
import { getEnvironment } from './get-environment';

const propFilter: { lineColumn?: number; message: string; newMessage: string; reason: string }[] = [
  {
    lineColumn: 0,
    message: 'Script error',
    newMessage: 'Script error. A javascript from another domain has an error',
    reason: 'these script errors are outside this domain and are probably plugins.',
  },
  {
    message: 'learningToolsRuntimeBridge',
    newMessage: 'Script error. TypeError: undefined is not an object (evaluating __gCrWeb.learningToolsRuntimeBridge.raiseMessageFromHost)',
    reason: 'These script errors are outside this domain and is a google? plugin for IOS Safari.',
  },
  {
    message: 'change_ua',
    newMessage: `Uncaught SyntaxError: Identifier 'change_ua' has already been declared. Plugin??`,
    reason: 'These script errors are outside this domain and are probably plugins.',
  },
];
const exceptionFilter: { functionName: string; message: string; newMessage: string; reason: string }[] = [
  {
    functionName: '_convertKeyframesToObject',
    message: 'Object.fromEntries',
    newMessage: 'Script error. A javascript from another domain has an error',
    reason: 'Old browser without support for Object.fromEntries.',
  },
  {
    functionName: '',
    message: 'could not find matching config for state',
    newMessage: `state code for login not valid `,
    reason: 'User start/refresh a link with state and code which already have been processed.',
  },
];

export const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: getInstrumentationKey(window.location),
    enableAutoRouteTracking: true,
    disableCookiesUsage: true,

    loggingLevelTelemetry: 0,
  },
});

appInsights.loadAppInsights();
appInsights.addTelemetryInitializer((envelope: ITelemetryItem) => {
  envelope.baseData ??= {};
  const properties = envelope.baseData['properties'];
  correctException(envelope);
  if (properties) {
    Object.assign(properties, AppInsightsService.extraProperties);
  }
  if (envelope.tags) {
    const tags = envelope.tags;
    const bot = /bot|crawl|spider/i.test(window.navigator.userAgent);
    if (bot) {
      tags['ai.device.id'] = tags['ai.device.type'] = 'bot';
    }
    if (AppInsightsService.userId) {
      tags['ai.session.id'] = AppInsightsService.userId;
    }
    if (AppInsightsService.tenantId) {
      tags['ai.tenant.id'] = AppInsightsService.tenantId;
    }
  }
});

@Injectable({ providedIn: 'root' })
export class AppInsightsService {
  static userId?: string | null;
  static tenantId?: string | null;

  static extraProperties: Record<string, string> = {};

  static trackEvent(event: IEventTelemetry): void {
    console.log(event);
    if (getEnvironment(window.location) !== 'local') {
      appInsights.trackEvent(event);
    }
  }

  static trackTraceInformation = (message: string, properties?: ICustomProperties) =>
    AppInsightsService.trackTrace(message, SeverityLevel.Information, properties);

  static trackTraceWarn = (message: string, properties?: ICustomProperties) =>
    AppInsightsService.trackTrace(message, SeverityLevel.Warning, properties);

  static trackTraceError = (message: string, properties?: ICustomProperties) =>
    AppInsightsService.trackTrace(message, SeverityLevel.Error, properties);

  static trackTrace(message: string, severityLevel: SeverityLevel, properties?: ICustomProperties): void {
    console.warn(message, properties);
    if (getEnvironment(window.location) !== 'local') {
      appInsights.trackTrace({ message, severityLevel, properties });
    }
  }

  static logError(exception: Error, properties?: ICustomProperties): void {
    Object.assign(properties ?? {}, (window as unknown as { lastRequest: unknown }).lastRequest);
    Object.assign(properties ?? {}, (window as unknown as { lastResponse: unknown }).lastResponse);
    console.error(exception, properties);
    if (getEnvironment(window.location) !== 'local') {
      appInsights.trackException({ exception, properties });
    }
  }
}

function correctException(envelope: ITelemetryItem) {
  correctScriptErrors(envelope);
  correctExceptions(envelope);
}

function correctScriptErrors(envelope: ITelemetryItem) {
  if (!envelope.baseData) {
    return;
  }
  const baseData = envelope.baseData;
  const properties = baseData!['properties'];
  if (properties) {
    const lineColumn = properties.lineNumber + properties.columnNumber;
    const message = properties.message ?? '';
    for (const prop of propFilter) {
      if (lineColumn === (prop.lineColumn ?? lineColumn) && message.includes(prop.message)) {
        baseData['severityLevel'] = 1;
        baseData['message'] = prop.newMessage;
        envelope.baseType = 'MessageData';
        return;
      }
    }
  }
}
function correctExceptions(envelope: ITelemetryItem) {
  if (!envelope.baseData || !Array.isArray(envelope.baseData['exceptions'])) {
    return;
  }
  const baseData = envelope.baseData;
  const ex = baseData['exceptions'][0] as Error;
  const functionName = ex.stack?.split('\n')[1].trim().split(' ')[1];
  const message = ex.message;
  if (functionName && message) {
    for (const prop of exceptionFilter) {
      if (functionName.includes(prop.functionName) && message.includes(prop.message)) {
        baseData['severityLevel'] = 1;
        baseData['message'] = prop.newMessage;
        envelope.baseType = 'MessageData';
        return;
      }
    }
  }
}

function getInstrumentationKey(location: Location): string {
  const env = getEnvironment(location);
  const key =
    env === 'development' || env === 'local'
      ? '704b4a59-377b-4d9b-be5c-3047b4003cbc'
      : env === 'test'
        ? '57e82c2f-4e53-4dbf-9a0d-b8dbe5eac9d1'
        : env === 'staging'
          ? '9bf358cf-b9ea-4f79-b738-cf33bb5f10da'
          : 'd3429dc6-8984-4ecb-b390-bd694bfaff01';
  return key;
}
