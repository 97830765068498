import { Injectable, InjectionToken } from '@angular/core';
import { TranslocoService } from '@jsverse/transloco';
import { DefaultValues, getItemProjectSpecific, isType, safeLocalStorage, setItemProjectSpecific, ucFirst } from '@shared/util/code';
import { AppInsightsService } from '@shared/util/infrastructure';
import { Category, MenuItem } from '@shared/util/response-model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationService {
  // Add the items to the interface above as well and implement getTranslation below.
  static readonly translatableProperties = ['suffix', 'hint', 'placeholder', 'prefix', 'info'];
  static token: InjectionToken<string>;
  static dictionaries: Record<string, Record<string, string>> = {};
  static get localeISO() {
    return TranslationService.locale.substring(0, 5);
  }

  static localIsSet$ = new BehaviorSubject<void>(void 0);

  private static _locale: string | undefined;

  constructor(private translocoService: TranslocoService) {}

  static get locale() {
    return TranslationService._locale ?? DefaultValues.englishLanguageSet.locale;
  }

  static init(defaultLocal: string) {
    // Browser will clear localStorage after inactivity.
    // saveStorage will save a copy but only with .setItem. So not after a browser refresh.
    // After inactivity a 401 will send the user to the login. safeStorage will return undefined.
    TranslationService._locale = getItemProjectSpecific(DefaultValues.locale) ?? defaultLocal;
    TranslationService.localIsSet$.next();
  }

  static getTranslation<T>(node: T) {
    const localeId = TranslationService.locale;

    TranslationService.trySetProperty(node, 'text', localeId);

    if (
      isType(node, '_text') &&
      node._text &&
      localeId in node._text &&
      isType(node, '_reportText') &&
      node._reportText &&
      !(localeId in node._reportText)
    ) {
      // No translation for _reportText, but there is a translation in _text!
      // Set reportText = undefined to show the translated text field.
      if (isType(node, 'reportText')) {
        node.reportText = undefined;
      }
    } else {
      TranslationService.trySetProperty(node, 'reportText', localeId);
    }

    this.translatableProperties.forEach((x) => TranslationService.trySetProperty(node, x, localeId));
  }

  static trySetProperty<T>(node: T, propName: string, localeId: string) {
    if (isType(node, propName)) {
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      (node[propName] as string) = ((node['_' + propName] as Record<string, string>) ?? {})[localeId] ?? (node[propName] as string);
    }
  }

  static getTextTranslations(obj: { text: string; _text?: Record<string, string>; reportText?: string; _reportText?: Record<string, string> }) {
    const localeId = TranslationService.locale;
    return isType(obj, '_reportText') && obj._reportText && localeId in obj._reportText
      ? obj._reportText[localeId]
      : isType(obj, '_text') && obj._text && localeId in obj._text
        ? obj._text[localeId]
        : isType(obj, 'reportText') && obj.reportText
          ? obj.reportText
          : obj.text;
  }

  static translateNodes<T>(nodes: Record<string, T>) {
    Object.keys(nodes).forEach((x) => {
      const node = nodes[x];
      TranslationService.getTranslation<T>(node);

      if (isType(node, 'categories')) {
        node.categories.forEach((c: Category) => {
          c.text = TranslationService.getTextTranslations(c);
          if (isType(c, 'info')) {
            TranslationService.trySetProperty(c, 'info', TranslationService.locale);
          }
        });
      }

      if (isType(node, 'footer')) {
        (node.footer.menu ??= []).forEach((c: MenuItem) => (c.text = TranslationService.getTextTranslations(c)));
      }

      if (isType(node, 'header')) {
        (node.header.menu ??= []).forEach((c: MenuItem) => (c.text = TranslationService.getTextTranslations(c)));
      }
    });
  }

  translateItem(key: string, args?: { key: string; value: string }[]): string {
    // Try first with the "whole" locale, including form of address. If nothing found then use 5 letters
    const locale = TranslationService.locale;
    let translation = this.tryGetTranslation(locale, key) ?? this.tryGetTranslation(locale.substring(0, 5), key);

    if (!translation) {
      // try the default main languages or fallback to the original key and notify it.
      const defaults = DefaultValues.getDefaultTranslationsFromWindow(locale.substring(0, 5));
      translation = key in defaults ? defaults[key] : ucFirst(this.translocoService.translate(key)) || key;
      if (translation.toLowerCase() === key.toLowerCase()) {
        AppInsightsService.trackTraceWarn(`The translation is missing for dictionary item '${key}' and locale '${locale}'.`);
      }
    }

    if (args && translation) {
      args.forEach((x) => (translation = translation!.replace(new RegExp(x.key), x.value)));
    }

    return translation;
  }

  changeLocaleId(localeId: string) {
    setItemProjectSpecific(DefaultValues.locale, localeId);
    window.location.reload();
  }

  changeLocaleIdWithoutReload(localeId: string) {
    setItemProjectSpecific(DefaultValues.locale, localeId);
    TranslationService._locale = localeId;
  }

  private tryGetTranslation(locale: string, key: string) {
    const translation =
      key in TranslationService.dictionaries && locale in TranslationService.dictionaries[key] ? TranslationService.dictionaries[key][locale] : null;
    return translation === '' ? null : translation;
  }
}

export function getAndSaveDebugValue() {
  if (location.search.includes('debug=0')) {
    safeLocalStorage.setItem('debug', '0');
    return false;
  }
  if (safeLocalStorage.getItem('debug') === '1' || location.search.includes('debug=1')) {
    safeLocalStorage.setItem('debug', '1');
    return true;
  }
  return false;
}

export function updateTextWithDebugInfo<T>(node: T) {
  if (isType(node, 'name')) {
    if (isType(node, 'text') && !node.text.includes(node.name)) {
      node.text = node.name + ' - ' + node.text;
    }
    if (isType(node, 'reportText') && !(node.reportText ?? '').includes(node.name) && node.reportText) {
      node.reportText = node.name + ' - ' + node.reportText;
    }
  }
}
