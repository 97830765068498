import { HttpErrorResponse } from '@angular/common/http';
import { ucFirst } from '@shared/util/code';
import { TranslationService } from '@shared/util/translations';
import { getEnvironment } from './get-environment';

export class DesanErrorHandler {
  private readonly isLocal = getEnvironment(location) === 'local';

  constructor(
    private error: Error,
    private translationService: TranslationService,
  ) {}

  getAlertMessage(): ErrorMessage {
    const rejectionError = (this.error as rejectionError).rejection as Error | undefined;

    if (rejectionError) {
      this.error = rejectionError;
    }
    const apiError = this.error as ApiException | HttpErrorResponse; //ApiException and HttpErrorResponse use the field status

    if (apiError.status >= 0) {
      return this.getApiError(apiError);
    }

    if (this.error instanceof UserError) {
      let title = this.translationService.translateItem(this.error.title);
      let message = this.translationService.translateItem(this.error.message);

      // Check if the title/message is translated, if not do not use it, except on local
      if (!this.isLocal) {
        title = title.endsWith(this.error.title) ? this.error.title : title;
        message = message.endsWith(this.error.message) ? this.error.message : message;
      }

      return { title: ucFirst(title), message: ucFirst(message), isDeveloperError: false };
    }

    const importModuleErrors: readonly string[] = ['Failed to fetch dynamically imported module', 'Failed to load module script'];
    if (importModuleErrors.includes(this.error.message.split(':')[0])) {
      return {
        title: ucFirst(this.translationService.translateItem('_errors_questionnaire_title_refresh')),
        message: this.translationService.translateItem('_errors_questionnaire_refresh'),
        isDeveloperError: false,
        buttonEvent: () => location.reload(),
      };
    }
    const alert = {
      title: this.error.message || 'unknown. TODO find out',
      message: JSON.stringify(this.error, Object.getOwnPropertyNames(this.error)).replace(/(\\n)/g, '<br>'),
      isDeveloperError: true,
    };

    return alert;
  }

  shouldIgnore(): boolean {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    return this.error.message.includes('The operation is insecure') ?? false; // old errors on some fireFox browsers
  }

  private getApiError(error: ApiException | HttpErrorResponse) {
    const errObj = { apiStatus: error.status + ' ', isDeveloperError: false };
    const trl = this.translationService.translateItem.bind(this.translationService);
    switch (error.status) {
      case 0:
        return { ...errObj, title: trl('_errors_general_api_0'), message: '' };
      case 401:
        return { unauthorized: true } as ErrorMessage;
      case 403:
        return { ...errObj, title: trl('_errors_general_title_no_rights'), message: trl('_errors_general_api_403') };
      case 404:
        return { ...errObj, title: trl('_errors_general_title_not_available'), message: trl('_errors_general_api_404'), isDeveloperError: true };
      case 503:
        return { ...errObj, title: trl('_errors_general_title_not_available'), message: trl('_errors_general_api_503') };
      case 504:
        return { ...errObj, title: trl('_errors_general_title_not_available'), message: trl('_errors_general_api_503') };
    }
    const angularError = error as HttpErrorResponse;
    const nSwagError = error as ApiException;
    const result: ProblemDetails = angularError.error ? angularError.error : JSON.parse(nSwagError.response);
    let title = (result.title ||= error.status + '' || '_errors_general_title').replace(/(?:\r\n|\r|\n)/g, '<br>');
    let message = (result.detail || error.message || '_errors_general_description')
      .replace(/(?:\r\n|\r|\n)/g, '<br>')
      .split(':line')
      .join(`<span style='background-color:yellow'>:line</span>`);

    const translatedTitle = trl(title);
    const translatedMsg = trl(message);

    title = translatedTitle.endsWith(title) && !this.isLocal ? title : translatedTitle;
    message = translatedMsg.endsWith(message) && !this.isLocal ? message : translatedMsg;

    // Some error message can consist of a part before the colon. Only the first part needs to be translated.
    if (message.includes(':')) {
      const parts = message.split(':');
      const part0 = parts[0];

      parts.splice(0, 1);
      const part1 = parts.join(':');

      if (trl(part0) !== part0) {
        message = trl(part0) + ':' + part1;
      }
    }

    return { ...errObj, title: ucFirst(title), message: ucFirst(message), isDeveloperError: result.type === 'Developer' };
  }
}
export type ProblemDetails = {
  title: string;
  detail: string;
  type: string;
};

export type ErrorMessage = {
  unauthorized?: boolean;
  message: string;
  apiStatus?: string;
  title: string;
  isDeveloperError: boolean;
  buttonEvent?: () => void;
};
export class ApiException extends Error {
  status: number;
  response: string;
  headers: { [key: string]: string };

  constructor(message: string, status: number, response: string, headers: { [key: string]: string }) {
    super();
    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
  }
}
export class UserError extends Error {
  title: string;
  constructor(message: string, title: string) {
    super(message);
    this.title = title;
    this.name = 'User';
  }
}

export class rejectionError extends Error {
  rejection?: Error;
}
