import { Injectable } from '@angular/core';
import { OidcSecurityService } from 'angular-auth-oidc-client';
import { BehaviorSubject, distinct, of, ReplaySubject, switchMap } from 'rxjs';
import { ImpersonateService, ImpersonateUser } from './impersonate.service';
export type CurrentOrImpersonateUser = {
  isAuthenticated: boolean;
  userId: number;
  name: string;
  tenantId: number;
  lastLoginDate: Date;
  email: string;
  role: string;
  isImpersonateUser: boolean;
  impersonateUser?: { role: string; name: string };
  orgName: string;
  orgRole: string;
};
const unknownUser: CurrentOrImpersonateUser = {
  email: '',
  isImpersonateUser: false,
  isAuthenticated: false,
  lastLoginDate: new Date(),
  name: '',
  orgName: '',
  orgRole: '',
  role: '',
  tenantId: 0,
  userId: 0,
};
@Injectable({ providedIn: 'root' })
export class UserService {
  currentOrImpersonateUser: CurrentOrImpersonateUser = Object.assign({}, unknownUser);
  currentOrImpersonateUser$ = new BehaviorSubject(this.currentOrImpersonateUser);
  tryImpersonateDone$ = new ReplaySubject<void>(1);
  constructor(
    oidc: OidcSecurityService,
    private impersonateService: ImpersonateService,
  ) {
    // isAuthenticated is triggered for every oidc.checkAuth. If used in activated this can cause looping. Only trigger distinct userIds
    oidc.isAuthenticated$
      .pipe(
        switchMap((x) => (x.isAuthenticated ? oidc.getUserData() : of(undefined))),
        distinct((x) => x?.sub),
      )
      .subscribe(async (x) => await this.updateUserData(x));
    impersonateService.impersonate$.subscribe((x) => this.updateImpersonateAndTriggerChange(x));
  }

  private async updateUserData(user?: JwtData): Promise<void> {
    if (user) {
      const clone = Object.assign({}, this.currentOrImpersonateUser);
      Object.assign(this.currentOrImpersonateUser, this.getUserByOidc(user));
      const service = this.impersonateService;
      const result = (await service.tryRestoreClientSideUser()) ?? (await service.tryRestoreServerSideUser(+user.sub));
      this.updateImpersonateData(result);
      this.triggerEventOnChange(clone, this.currentOrImpersonateUser);
      // RoleRoute must wait until impersonate is finished after application start
      this.tryImpersonateDone$.next();
      return;
    }
    // logout clear user data
    else {
      Object.assign(this.currentOrImpersonateUser, unknownUser);
    }
    this.currentOrImpersonateUser$.next(this.currentOrImpersonateUser);
  }

  private triggerEventOnChange(old: CurrentOrImpersonateUser, current: CurrentOrImpersonateUser) {
    if (old.userId !== current.userId || old.name !== current.name || old.role !== current.role) {
      this.currentOrImpersonateUser$.next(this.currentOrImpersonateUser);
    }
  }

  private getUserByOidc(user: JwtData): CurrentOrImpersonateUser {
    return {
      userId: +user.sub,
      tenantId: +user.tenantId,
      email: user.email,
      name: user.name,
      role: user.role,
      orgName: user.name,
      orgRole: user.role,
      lastLoginDate: user.lastLoginDate ? new Date(Date.parse(user.lastLoginDate)) : undefined!,
      isImpersonateUser: false,
      isAuthenticated: true,
    };
  }

  private updateImpersonateAndTriggerChange(user: ImpersonateUser) {
    const clone = Object.assign({}, this.currentOrImpersonateUser);
    this.updateImpersonateData(user);
    this.triggerEventOnChange(clone, this.currentOrImpersonateUser);
  }

  private updateImpersonateData(user: ImpersonateUser) {
    this.currentOrImpersonateUser.isImpersonateUser = !!user;
    this.currentOrImpersonateUser.name = user ? `${user.name}` : this.currentOrImpersonateUser.orgName;
    this.currentOrImpersonateUser.role = user ? `${user.role}` : this.currentOrImpersonateUser.orgRole;
    this.currentOrImpersonateUser.impersonateUser = user ? { name: user.name, role: user.role } : undefined;
  }
}

type JwtData = {
  sub: string;
  role: string;
  name: string;
  tenantId: string;
  email: string;
  lastLoginDate: string;
};
