import { OverlayRef } from '@angular/cdk/overlay';
import { DestroyRef, Directive } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatDialogRef } from '@angular/material/dialog';
import { filter } from 'rxjs/operators';

@Directive({
  selector: 'lsuCloseOnEscape,[lsuCloseOnEscape]',
  standalone: true,
})
export class CloseOnEscapeDirective {
  o: OverlayRef = null!;
  constructor(
    dialogRef: MatDialogRef<unknown>,
    private destroyRef: DestroyRef,
  ) {
    dialogRef
      .keydownEvents()
      .pipe(
        filter((x: KeyboardEvent) => x.key === 'Escape'),
        takeUntilDestroyed(destroyRef),
      )
      .subscribe(() => dialogRef.close());
  }
}
