/* some code is from https://github.com/angular/components/blob/main/src/material/schematics/ng-generate/theme-color/index.ts */
import { argbFromHex, DynamicScheme, hexFromArgb, Theme, themeFromSourceColor, TonalPalette } from '@material/material-color-utilities';
import { MainColors, ThemeModel } from '@shared/feature/themes';

const themePrefix = '--m3';
const hueTones = [0, 10, 20, 25, 30, 35, 40, 50, 60, 70, 80, 90, 95, 98, 99, 100];

export function setFixedColors(theme: ThemeModel) {
  setCSSVariable(`${themePrefix}-background-color`, theme.backgroundColors.lightColor);
  setCSSVariable(`${themePrefix}-primary-color`, theme.mainColors.primaryColor);
  setCSSVariable(`${themePrefix}-on-primary-color`, theme.textColors.primaryColor);
  setCSSVariable(`${themePrefix}-secondary-color`, theme.mainColors.accentColor);
  setCSSVariable(`${themePrefix}-on-secondary-color`, theme.textColors.accentColor);
  setCSSVariable(`${themePrefix}-error-color`, theme.mainColors.warnColor);
  setCSSVariable(`${themePrefix}-on-error-color`, '#ffffff');
}

export function setCSSVariablesFromM3Theme(mainColors: MainColors) {
  for (const [variant, color] of Object.entries(mainColors)) {
    const m3ThemeColorsJSON = themeFromSourceColor(argbFromHex(color));
    const paletteKey = variant
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .toLowerCase()
      .replace('accent', 'secondary')
      .replace('warn', 'error');

    for (const tone of hueTones) {
      const p = hexFromArgb(m3ThemeColorsJSON.palettes.primary.tone(tone));
      setCSSVariable(`${themePrefix}-${paletteKey}-${tone}`, p);
    }
  }

  setCSSVariablesContrastColors(
    themeFromSourceColor(argbFromHex(mainColors.primaryColor)),
    themeFromSourceColor(argbFromHex(mainColors.accentColor)),
  );
}

function setCSSVariablesContrastColors(primary: Theme, secondary: Theme) {
  const darkHighContrastColorScheme = getMaterialDynamicScheme(
    primary.palettes.primary,
    secondary.palettes.primary,
    primary.palettes.tertiary,
    primary.palettes.neutral,
    primary.palettes.neutralVariant,
    /* isDark */ true,
    /* contrastLevel */ 1.0,
  );
  const lightHighContrastColorScheme = getMaterialDynamicScheme(
    primary.palettes.primary,
    secondary.palettes.primary,
    primary.palettes.tertiary,
    primary.palettes.neutral,
    primary.palettes.neutralVariant,
    /* isDark */ false,
    /* contrastLevel */ 1.0,
  );

  const darkOverrides = getHighContrastOverrides(darkHighContrastColorScheme);
  const lightOverrides = getHighContrastOverrides(lightHighContrastColorScheme);
  for (const [key, value] of lightOverrides!.entries()) {
    setCSSVariable(`${themePrefix}-light-highcontrast-${key}`, value);
    setCSSVariable(`${themePrefix}-dark-highcontrast-${key}`, darkOverrides.get(key)!);
  }
}

function setCSSVariable(name: string, value: string) {
  document.documentElement.style.setProperty(name, value);
}

function getHighContrastOverrides(colorScheme: DynamicScheme): Map<string, string> {
  const overrides = new Map<string, string>();
  // Set system variables with values from primary palette
  overrides.set('primary', hexFromArgb(colorScheme.primary));
  overrides.set('on-primary', hexFromArgb(colorScheme.onPrimary));
  overrides.set('primary-container', hexFromArgb(colorScheme.primaryContainer));
  overrides.set('on-primary-container', hexFromArgb(colorScheme.onPrimaryContainer));
  overrides.set('inverse-primary', hexFromArgb(colorScheme.inversePrimary));
  overrides.set('primary-fixed', hexFromArgb(colorScheme.primaryFixed));
  overrides.set('primary-fixed-dim', hexFromArgb(colorScheme.primaryFixedDim));
  overrides.set('on-primary-fixed', hexFromArgb(colorScheme.onPrimaryFixed));
  overrides.set('on-primary-fixed-variant', hexFromArgb(colorScheme.onPrimaryFixedVariant));

  // Set system variables with values from secondary palette
  overrides.set('secondary', hexFromArgb(colorScheme.secondary));
  overrides.set('on-secondary', hexFromArgb(colorScheme.onSecondary));
  overrides.set('secondary-container', hexFromArgb(colorScheme.secondaryContainer));
  overrides.set('on-secondary-container', hexFromArgb(colorScheme.onSecondaryContainer));
  overrides.set('secondary-fixed', hexFromArgb(colorScheme.secondaryFixed));
  overrides.set('secondary-fixed-dim', hexFromArgb(colorScheme.secondaryFixedDim));
  overrides.set('on-secondary-fixed', hexFromArgb(colorScheme.onSecondaryFixed));
  overrides.set('on-secondary-fixed-variant', hexFromArgb(colorScheme.onSecondaryFixedVariant));

  // Set system variables with values from tertiary palette
  overrides.set('tertiary', hexFromArgb(colorScheme.tertiary));
  overrides.set('on-tertiary', hexFromArgb(colorScheme.onTertiary));
  overrides.set('tertiary-container', hexFromArgb(colorScheme.tertiaryContainer));
  overrides.set('on-tertiary-container', hexFromArgb(colorScheme.onTertiaryContainer));
  overrides.set('tertiary-fixed', hexFromArgb(colorScheme.tertiaryFixed));
  overrides.set('tertiary-fixed-dim', hexFromArgb(colorScheme.tertiaryFixedDim));
  overrides.set('on-tertiary-fixed', hexFromArgb(colorScheme.onTertiaryFixed));
  overrides.set('on-tertiary-fixed-variant', hexFromArgb(colorScheme.onTertiaryFixedVariant));

  // Set system variables with values from neutral palette
  overrides.set('background', hexFromArgb(colorScheme.background));
  overrides.set('on-background', hexFromArgb(colorScheme.onBackground));
  overrides.set('surface', hexFromArgb(colorScheme.surface));
  overrides.set('surface-dim', hexFromArgb(colorScheme.surfaceDim));
  overrides.set('surface-bright', hexFromArgb(colorScheme.surfaceBright));
  overrides.set('surface-container-lowest', hexFromArgb(colorScheme.surfaceContainerLowest));
  overrides.set('surface-container', hexFromArgb(colorScheme.surfaceContainer));
  overrides.set('surface-container-high', hexFromArgb(colorScheme.surfaceContainerHigh));
  overrides.set('surface-container-highest', hexFromArgb(colorScheme.surfaceContainerHighest));
  overrides.set('on-surface', hexFromArgb(colorScheme.onSurface));
  overrides.set('shadow', hexFromArgb(colorScheme.shadow));
  overrides.set('scrim', hexFromArgb(colorScheme.scrim));
  overrides.set('surface-tint', hexFromArgb(colorScheme.surfaceTint));
  overrides.set('inverse-surface', hexFromArgb(colorScheme.inverseSurface));
  overrides.set('inverse-on-surface', hexFromArgb(colorScheme.inverseOnSurface));
  overrides.set('outline', hexFromArgb(colorScheme.outline));
  overrides.set('outline-variant', hexFromArgb(colorScheme.outlineVariant));

  // Set system variables with values from error palette
  overrides.set('error', hexFromArgb(colorScheme.error));
  overrides.set('on-error', hexFromArgb(colorScheme.onError));
  overrides.set('error-container', hexFromArgb(colorScheme.errorContainer));
  overrides.set('on-error-container', hexFromArgb(colorScheme.onErrorContainer));

  // Set system variables with values from neutral variant palette
  overrides.set('surface-variant', hexFromArgb(colorScheme.surfaceVariant));
  overrides.set('on-surface-variant', hexFromArgb(colorScheme.onSurfaceVariant));

  return overrides;
}

/**
 * Gets color tonal palettes generated by Material from the provided color.
 * @param primaryPalette Tonal palette that represents primary.
 * @param secondaryPalette Tonal palette that represents secondary.
 * @param tertiaryPalette Tonal palette that represents tertiary.
 * @param neutralPalette Tonal palette that represents neutral.
 * @param neutralVariantPalette Tonal palette that represents neutral variant.
 * @param isDark Boolean to represent if the scheme is for a dark or light theme.
 * @param contrastLevel Number between -1 and 1 for the contrast level. 0 is the standard contrast
 * and 1 represents high contrast.
 * @returns Dynamic scheme for provided theme and contrast level
 */
export function getMaterialDynamicScheme(
  primaryPalette: TonalPalette,
  secondaryPalette: TonalPalette,
  tertiaryPalette: TonalPalette,
  neutralPalette: TonalPalette,
  neutralVariantPalette: TonalPalette,
  isDark: boolean,
  contrastLevel: number,
): DynamicScheme {
  return new DynamicScheme({
    sourceColorArgb: primaryPalette.keyColor.toInt(),
    variant: 6, // Variant.FIDELITY, used number representation since enum is not accessible outside of @material/material-color-utilities
    contrastLevel: contrastLevel,
    isDark: isDark,
    primaryPalette: primaryPalette,
    secondaryPalette: secondaryPalette,
    tertiaryPalette: tertiaryPalette,
    neutralPalette: neutralPalette,
    neutralVariantPalette: neutralVariantPalette,
  });
}
