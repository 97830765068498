import { InjectionToken } from '@angular/core';
import { getEnvironment } from '@shared/util/infrastructure';

export type ToolsBaseUrls = {
  surveyAdmin: string;
  surveyResponse: string;
  surveyEditor: string;
  reports: string;
  reportGroups: string;
};

export const PROJECT_GUID = new InjectionToken<string>('PROJECT_GUID');
export const API_PORTAL_BASE_URL = new InjectionToken<string>('API_PORTAL_BASE_URL');
export const API_EXPORT_BASE_URL = new InjectionToken<string>('API_EXPORT_BASE_URL');
export const API_NODEJS_BASE_URL = new InjectionToken<string>('API_NODEJS_BASE_URL');
export const API_BASE_URL = new InjectionToken<string>('API_BASE_URL');
export const API_REGISTRATION_BASE_URL = new InjectionToken<string>('');
export const API_EDITOR_BASE_URL = new InjectionToken<string>('');
export const API_REPORT_BASE_URL = new InjectionToken<string>('');
export const API_SINGLE_REPORT_BASE_URL = new InjectionToken<string>('');
export const API_RESPONSE_BASE_URL = new InjectionToken<string>('');
export const API_ADMIN_BASE_URL = new InjectionToken<string>('');
export const TOOLS_BASE_URLS = new InjectionToken<ToolsBaseUrls>('');
export function getToolBaseUrls(location: Location): ToolsBaseUrls {
  return getEnvironment(location) === 'local'
    ? {
        surveyAdmin: `https://${location.hostname}:4201/SurveyAdmin/`,
        reports: `https://${location.hostname}:4207/Reports/`,
        reportGroups: `https://${location.hostname}:4205/ReportGroups/`,
        surveyEditor: `https://${location.hostname}:4203/SurveyEditor/`,
        surveyResponse: `https://${location.hostname}:4202/SurveyResponse/`,
      }
    : {
        surveyAdmin: `${location.origin}/SurveyAdmin/`,
        reports: `${location.origin}/Reports/`,
        reportGroups: `${location.origin}/ReportGroups/`,
        surveyEditor: `${location.origin}/SurveyEditor/`,
        surveyResponse: `${location.origin}/SurveyResponse/`,
      };
}
