import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[lsuDirectivesFocus]',
  standalone: true,
})
export class FocusDirective {
  @Input() set lsuDirectivesFocus(_: string) {
    setTimeout(() => {
      const el = this.element.nativeElement.parentElement!.querySelector('input, lsu-monaco-editor') as HTMLElement | null;
      el?.focus();
    }, 0);
  }

  constructor(public element: ElementRef<HTMLElement>) {}
}

@Directive({
  selector: '[lsuDirectivesFocusIf]',
  standalone: true,
})
export class FocusIfDirective {
  @Input() set lsuDirectivesFocusIf(value: boolean) {
    if (value !== this.lastValue && value) {
      setTimeout(() => this.element.nativeElement.parentElement!.querySelector('input')?.focus(), 0);
    }
    this.lastValue = value;
  }

  private lastValue = false;

  constructor(public element: ElementRef<HTMLElement>) {}
}
