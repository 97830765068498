import { NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialog, MatDialogConfig, MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { TranslocoService } from '@jsverse/transloco';
import { ucFirst } from '@shared/util/code';
import { firstValueFrom } from 'rxjs';
import { CloseOnEscapeDirective } from '../close-on-escape.directive';

export interface DialogConfirmData {
  title?: string;
  message: string;
  okButton?: string;
  cancelButton?: string;
}

@Component({
  selector: 'lsu-dialog-confirm',
  templateUrl: './dialog-confirm.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CloseOnEscapeDirective, NgIf, MatDialogModule, MatButtonModule],
})
export class DialogConfirmComponent implements OnInit {
  title = '';
  message = '';
  okButton = ucFirst(this.translocoService.translate('common.ok'));
  cancelButton = ucFirst(this.translocoService.translate('common._actions.cancel'));

  constructor(
    public dialogRef: MatDialogRef<DialogConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogConfirmData,
    private translocoService: TranslocoService,
  ) {}

  static async open(dialog: MatDialog, config: MatDialogConfig<DialogConfirmData>): Promise<boolean | undefined> {
    return await firstValueFrom(dialog.open(DialogConfirmComponent, config).afterClosed());
  }

  ngOnInit(): void {
    if (this.data.title) {
      this.title = this.data.title;
    }

    this.message = this.data.message;

    if (this.data.cancelButton) {
      this.cancelButton = this.data.cancelButton;
    }

    if (this.data.okButton) {
      this.okButton = this.data.okButton;
    }
  }
}
