/* eslint-disable */
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpResponse, HttpResponseBase } from '@angular/common/http';
import { Observable, bindCallback, of, throwError } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

export type HttpMethod = 'post' | 'get' | 'delete' | 'put' | 'patch' | 'head';
const fileContentTypes = [
  'application/pdf',
  'application/x-msdownload',
  'application/json',
  'audio/mpeg',
  'application/x-spss-sav',
  'application/zip',
];

type options = { observe: 'response'; responseType: 'blob'; headers: HttpHeaders; body: string };

export function httpRequest<T>(methode: HttpMethod, http: HttpClient, url: string, options: options): Observable<T> {
  const windowAny = window as any;
  windowAny.lastRequestIndex ??= 0;
  const index = windowAny.lastRequestIndex++ % 10;
  windowAny.lastRequest = windowAny.lastRequest ?? [];
  windowAny.lastRequest[index] = { url, body: options.body };
  return http.request(methode, url, options).pipe(
    switchMap((response) => processT<T>(response)),
    catchError((errorResponse) => {
      // not 200-300 response or exception when processing response or client connection error
      if (errorResponse instanceof HttpResponseBase) {
        return throwException(errorResponse) as unknown as Observable<T>;
      } else {
        throw errorResponse;
      }
    }),
  );
}

function processT<T>(response: HttpResponse<Blob>): Observable<T> {
  const status = response.status;
  return status === 200 && response instanceof HttpResponse
    ? responseToT<T>(response)
    : status === 204
      ? of(null as unknown as T)
      : (throwException(response) as unknown as Observable<T>);
}

function responseToT<T>(response: HttpResponse<Blob>): Observable<T> {
  return response.headers.get('content-type') === 'application/x-protobuf'
    ? (blobToUnit8Array(response.body) as unknown as Observable<T>)
    : fileContentTypes?.includes(response.headers.get('content-type') || 'none')
      ? (blobToFile<T>(response) as unknown as Observable<T>)
      : blobToText(response.body).pipe(
          tap((x) => ((window as any).lastResponse = x)),
          map((x) => (x === '' ? (null as unknown as T) : (JSON.parse(x) as T))),
        );
}

function throwException(response: HttpResponseBase): Observable<ApiException> {
  const responseBlob =
    response instanceof HttpResponse
      ? response.body // server response is valid but somewhere in pipeline generated an error
      : response instanceof HttpErrorResponse
        ? response.error // contains json string with problem detail or an error object
        : undefined;
  const status = response.status;
  const headers = getHeaders(response);
  if (responseBlob instanceof Blob) {
    return blobToText(responseBlob).pipe(switchMap((responseText) => throwError(() => new ApiException(status, responseText, headers))));
  }
  return throwError(() => new ApiException(response.status, JSON.stringify(response), headers));
}

function getHeaders(response: HttpResponseBase): Record<string, string> {
  const headers: Record<string, string> = {};
  response.headers?.keys().forEach((c) => (headers[c] = response.headers.get(c) || ''));
  return headers;
}

function blobToText(blob: Blob | null): Observable<string> {
  const result = !blob ? of('') : bindCallback(blobToString)(blob).pipe(map((x) => x.target?.result as string));
  return result;
}

function blobToFile<T>(response: HttpResponse<Blob>): Observable<T> {
  const contentDisposition = response.headers ? response.headers.get('content-disposition') : undefined;
  const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
  const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
  return of({ fileName, data: response.body } as unknown as T);
}

function blobToUnit8Array(blob: Blob | null): Observable<Uint8Array> {
  const result = !blob ? of(new Uint8Array()) : bindCallback(blobToArrayBuffer)(blob).pipe(map((x) => x.target?.result as Uint8Array));
  return result;
}

function blobToString(blob: Blob, callback: (event: ProgressEvent<FileReader>) => void): void {
  const reader = new FileReader();
  reader.onload = callback;
  reader.readAsText(blob);
}

function blobToArrayBuffer(blob: Blob, callback: (event: ProgressEvent<FileReader>) => void): void {
  const reader = new FileReader();
  reader.onload = callback;
  reader.readAsArrayBuffer(blob);
}

export class ApiException extends Error {
  status: number;
  response: string;
  headers: { [key: string]: string };

  constructor(status: number, response: string, headers: { [key: string]: string }) {
    super();
    this.message = getApiError(status, response);
    this.status = status;
    this.response = response;
    this.headers = headers;
  }
}
function getApiError(status: number, response: string) {
  switch (status) {
    case 0:
      return 'No connection to server.';
    case 400:
      return 'bad request (old client?)';
    case 401:
      return 'unauthorized (not logged in.)';
    case 403:
      return 'no rights.';
    case 404:
      return 'Api interface does not exist. (old client?)';
    case 503:
      return 'Server unavailable.';
    case 504:
      return 'Gateway Timeout.';
  }
  try {
    return JSON.parse(response).title;
  } catch {
    return 'Unknown server error.';
  }
}

export interface FileResponse {
  data: Blob;
  fileName?: string;
}

export interface FileParameter {
  data: any;
  fileName: string;
}
