/*************************************************************************************************
 * WARNING: code used in generated typescript. Do not change without understanding consequence
 *************************************************************************************************/

import { QNode } from '@shared/util/response-model';

export type DefaultTranslationsWindow = typeof window & { defaultTranslations?: Record<string, Record<string, string>> };
export type TranslationItemsWindow = typeof window & { translatedItems: Record<string, string>; nodeNameIndex: Record<string, QNode> };

export class DefaultValues {
  static languageSet = { locale: 'nl-NL', description: 'Nederlands' };
  static englishLanguageSet = { locale: 'en-GB', description: 'English' };

  static locale = 'localeId';
  static _localeId = '_localeId';
  static availableSampleLang = 'availableSampleLang';
  static validationCode = 'validationCode';
  static validationCodeSeparator = '_';

  public static getDefaultTranslationsFromWindow(locale: string): Record<string, string> {
    const defaults = (window as DefaultTranslationsWindow).defaultTranslations ?? {};
    return locale in defaults ? (defaults[locale] ?? {}) : (defaults[this.englishLanguageSet.locale] ?? {});
  }
}
