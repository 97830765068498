import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { deepAssign } from '@shared/util/code';
import { DEFAULT_FONTS, FontSelection, getUnit } from '../shared/models/font.model';
import { ThemeModel } from '../shared/models/theme.model';
import { FontService } from './font.service';
import { ThemingService } from './theming.service';

@Injectable({
  providedIn: 'root',
})
export class TypographyService {
  typography: { [key: string]: FontSelection } = null!; // todo remove this property. This class cannot guarantee this value here. This value is only set if someone call setupTypographyLevel before using.

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private fontService: FontService,
  ) {}

  setupTypographyLevel(themeConfig: ThemeModel): void {
    // add missing DEFAULT_FONTS
    const subscriptFontDefined = Object.prototype.hasOwnProperty.call(themeConfig.typographyConfig, 'subscript');
    themeConfig.typographyConfig = { ...DEFAULT_FONTS, ...themeConfig.typographyConfig };
    if (!subscriptFontDefined) {
      // This questionnaire is created before the new subscript fontStyle and is never re-edited afterwards.
      // Copy old caption values into subscript to style the hints and errors as it used to be.
      deepAssign(themeConfig.typographyConfig.subscript, themeConfig.typographyConfig.caption);
      themeConfig.typographyConfig.subscript.fontSize = 0.8;
    }
    this.typography = themeConfig.typographyConfig;
    for (const [fontStyle, fontSelection] of Object.entries(themeConfig.typographyConfig)) {
      this.fontService.loadFont(fontSelection.fontFamily);
      Object.entries(fontSelection).forEach(([key, value]) => {
        this.setCssVariable(fontStyle, key as keyof FontSelection, value);
      });
    }
  }

  setCssVariable(fontStyle: string, key: keyof FontSelection, value: string) {
    const cssVariable = ThemingService.prependVariableName(ThemingService.convertCamelCaseToKebabCase(fontStyle + '-' + key));
    value += getUnit(key);
    this.document.documentElement.style.setProperty(cssVariable, value);
  }
}
